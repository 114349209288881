import moment from "moment";
import PaymentMoreDropdown from "../../Components/Form/Dropdowns/PaymentMoreDropdown";
import { ImageCell } from "./PaymentTableCells";
import { levelOptions } from "./dummies";

// brands table data
export const PaymentsCols = [
  { label: "InvoiceNo", field: "invoiceNo" },
  { label: "date", field: "date" },
  { label: "fee", field: "fee" },
  { label: "Semester", field: "semester" },
  { label: "Action", field: "action" },
];

export const paymentRows = (data: any, type?: string) => {
  const res = data?.map((payment: any) => {
    return {
      name: (
        <ImageCell
          avatar="/assets/images/SVG/user-1.svg"
          firstName={payment?.firstName}
          lastName={payment?.lastName}
          // userName="@hillary"
        />
      ),
      invoiceNo: <span>{payment?.invoiceNumber}</span>,
      semester: (
        <span>
          {payment?.semesterId === 1
            ? "First Semester"
            : payment?.semesterId === 2
            ? "Second Semester"
            : "N/A"}
        </span>
      ),
      gateway: <span>{payment?.paymentMeans}</span>,
      date: (
        <span>{moment(payment?.transactionDate).format("Do MMM YYYY")}</span>
      ),
      fee: <span>{payment?.feeSchedule?.feeScheduleName}</span>,
      receipt: (
        <span style={{ width: `150px` }}>{payment?.tellerNoOrReceiptId}</span>
      ),
      faculty: <span>{payment?.facultyName}</span>,
      amount: <span>₦ {payment?.amount}</span>,
      department: <span>{payment?.departmentName}</span>,
      action: type !== "UnSuccessful" && (
        <PaymentMoreDropdown paymentId={payment?.paymentId} />
      ),
    };
  });

  return res;
};

export const feeMappingCols = [
  { label: "Fee Schedule", field: "feeSchedule" },
  { label: "Pay By Installment", field: "payInstallment" },
  { label: "Department", field: "department" },
  { label: "Level", field: "level" },
  { label: "Session", field: "session" },
  { label: "Semester", field: "semester" },
  { label: "Gender", field: "gender" },
];

export const feeMappingRows = (
  data: any,
  feeSchedule: any,
  department: any,
  sessions: any,
  semesters: any
) => {
  const res = data?.map((payment: any) => {
    return {
      feeSchedule: (
        <span>
          {
            feeSchedule?.filter(
              (x: any) => x?.value === payment?.feeScheduleId
            )[0]?.label
          }
        </span>
      ),
      payInstallment: (
        <span>{payment?.paymentInstallmentId === 1 ? "True" : "False"}</span>
      ),
      level: (
        <span>
          {
            levelOptions?.filter((x: any) => x?.value === payment?.levelId)[0]
              ?.label
          }
        </span>
      ),
      session: (
        <span>
          {
            sessions?.filter((x: any) => x?.value === payment?.sessionId)[0]
              ?.label
          }
        </span>
      ),
      receipt: (
        <span style={{ width: `150px` }}>{payment?.tellerNoOrReceiptId}</span>
      ),
      semester: (
        <span>
          {
            semesters?.filter((x: any) => x?.value === payment?.semesterId)[0]
              ?.label
          }
        </span>
      ),
      gender: <span>{payment?.genderId === 1 ? "Male" : "Female"}</span>,
      department: (
        <span>
          {department?.filter((x: any) => x?.value === payment?.departmentId)[0]
            ?.label || "N/A"}
        </span>
      ),
      action: <PaymentMoreDropdown paymentId={payment?.paymentId} />,
    };
  });

  return res;
};
