import { store } from "../../Redux/app/store";
import {
  setACknowledgementSlip,
  setAdmissionLetter,
  setApplicantDetails,
  setGettingApplicantsDetails,
  setPrintingAcknowledgementSlip,
  setPrintingAdmissionLetter,
} from "../../Redux/features/admissionSlice";
import { logoutUser } from "../../Redux/features/userSlice";
import api from "../baseUri";
import { toast } from "react-toastify";

export const printAdmissionLetter = async () => {
  store.dispatch(setPrintingAdmissionLetter(true));
  await api
    .get("Student/PrintAmdissionLetter")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmissionLetter(data));
      store.dispatch(setPrintingAdmissionLetter(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setPrintingAdmissionLetter(false));
    });
};

export const printAdmissionAcknowledgementSlip = async () => {
  store.dispatch(setPrintingAcknowledgementSlip(true));
  await api
    .get("Student/PrintAdmissionAcknowledgemntSlip")
    .then((res) => {
      const { data } = res;
      store.dispatch(setACknowledgementSlip(data));
      store.dispatch(setPrintingAcknowledgementSlip(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setPrintingAcknowledgementSlip(false));
    });
};

export const getApplicantsDetails = async () => {
  store.dispatch(setGettingApplicantsDetails(true));
  await api
    .get("/Student/Applicant")
    .then((res) => {
      const { data } = res;
      store.dispatch(setApplicantDetails(data));
      store.dispatch(setGettingApplicantsDetails(false));
    })
    .catch((err: any) => {
      if (err?.response) {
        toast.error(err?.response?.data);
        store.dispatch(logoutUser());
      }
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingApplicantsDetails(false));
    });
};
