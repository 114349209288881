import { Routes, Route } from "react-router-dom";
import {
  MainBody,
  MainBodyContainer,
  RouteContainer,
} from "../Pages/PrivatePages/style";
import Header from "../Components/Nav/Header";
import Sidebar from "../Components/Nav/Sidebar";
import { useEffect } from "react";
import {
  getActiveSession,
  getAdmissionTypes,
  getBankList,
  getDepartments,
  getFaculties,
  getFeeschedule,
  getHostel,
  getHostelBlock,
  getPaymentMean,
  getSemester,
  getSessions,
} from "../Apis/Private/Utils";
import Clearance from "../Pages/PrivatePages/Clearance";
import CourseReg from "../Pages/PrivatePages/CourseReg";
import Result from "../Pages/PrivatePages/Results";
import Dashboard from "../Pages/PrivatePages/Dashboard";
import Account from "../Pages/PrivatePages/Account";
import ChangePassword from "../Pages/PrivatePages/Account/ChangePassword";
import PaymentHistory from "../Pages/PrivatePages/Payments";
import GenerateInvoice from "../Pages/PrivatePages/Payments/GenerateInvoice";
import Hostel from "../Pages/PrivatePages/Hostel";
import PaymentReceipt from "./../Pages/PrivatePages/Payments/PaymentReceipt";
import InvoicePage from "../Pages/PrivatePages/Payments/InvoicePage";
import { useAppSelector } from "../Redux/app/hooks";
import { selectUser } from "../Redux/features/userSlice";
import AdmissionStatus from "../Pages/PrivatePages/Applicants/AdmissionStatus";
import AcceptanceFee from "../Pages/PrivatePages/Applicants/AcceptanceFee";
import HostelHistory from "../Pages/PrivatePages/Hostel/HostelHistory";
import HostelCard from "../Pages/PrivatePages/Hostel/HostelCard";

const PrivateRoutes = () => {
  const { currentUser } = useAppSelector(selectUser);

  useEffect(() => {
    getSessions();
    getSemester();
    getFeeschedule();
    getPaymentMean();
    getHostel();
    getFaculties();
    getDepartments();
    getActiveSession();
    getHostelBlock();
    getBankList();
    getAdmissionTypes();
  }, []);

  console.log(currentUser);

  return (
    <RouteContainer>
      <Header />
      <MainBodyContainer>
        <Sidebar />
        <MainBody>
          {currentUser?.roleId === 10 && (
            <Routes>
              <Route path="/" element={<Dashboard />} />
              {/* <Route path="/admission/admission-letter" element={<AdmissionLetter />} />
            <Route path="/admission/acknowledgement-slip" element={<AcknowledgementSlip />} /> */}
              <Route path="/clearance" element={<Clearance />} />
              <Route path="/course-registration" element={<CourseReg />} />
              <Route path="/result" element={<Result />} />
              <Route path="/account" element={<Account />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route
                path="/payment/payment-history"
                element={<PaymentHistory />}
              />
              <Route
                path="/payment/view-payment-receipt/:id"
                element={<PaymentReceipt />}
              />
              <Route
                path="/payment/generate-invoice"
                element={<GenerateInvoice />}
              />
              <Route
                path="/payment/view-generated-invoice"
                element={<InvoicePage />}
              />
              <Route path="/hostel/hostel-booking" element={<Hostel />} />
              <Route
                path="/hostel/hostel-history"
                element={<HostelHistory />}
              />
              <Route
                path="/hostel/print-hostel-card"
                element={<HostelCard />}
              />
            </Routes>
          )}
          {currentUser?.roleId === 2 && (
            <Routes>
              <Route path="/" element={<AdmissionStatus />} />
              <Route path="/pay-acceptance-fee" element={<AcceptanceFee />} />
            </Routes>
          )}
        </MainBody>
      </MainBodyContainer>
    </RouteContainer>
  );
};

export default PrivateRoutes;
