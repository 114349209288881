import React, { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SmallButton from "../Components/Form/Buttons/SmallButton";
import { FaPrint } from "react-icons/fa";
import styled from "styled-components";
import { media } from "../Screens";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { ItemsCenterJustifyBtw } from "./PrivatePages/style";
import TextCenteredInput from "../Components/Form/Inputs/TextCenteredInput";
import { CustomButton } from "../Components/Form/Buttons/styles";
import { FaMoneyBills } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../Redux/features/userSlice";
import { useAppDispatch } from "../Redux/app/hooks";

interface ReactPrintProp {
  children: any;
  pay?: boolean;
  etransactPaymentLink?: string;
  remitaObject?: any;
  isAdmitted?: boolean;
}

const Printer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.tablet} {
    width: 70%;
  }
`;

const ReactPrint: FC<ReactPrintProp> = ({
  children,
  pay,
  etransactPaymentLink,
  remitaObject,
  isAdmitted,
}) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    removeAfterPrint: true,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <Printer>
      <div ref={contentToPrint}>{children}</div>
      <ItemsCenterJustifyBtw
        style={{ alignItems: "center", justifyContent: "flex-start" }}
      >
        <SmallButton
          buttonText="Print"
          leftIcon={<FaPrint />}
          handleClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        />
        {isAdmitted && (
          <SmallButton
            buttonText="Pay Acceptance Fee"
            leftIcon={<FaMoneyBills />}
            handleClick={() => navigate("/pay-acceptance-fee")}
            customStyle={{ height: "50px" }}
          />
        )}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {pay && etransactPaymentLink && (
            <SmallButton
              buttonText="Pay with e-Tranzact CREDO"
              leftIcon={<RiMoneyDollarCircleLine />}
              handleClick={() => {
                window.open(`${etransactPaymentLink}`, "_blank");
                dispatch(logoutUser());
              }}
            />
          )}
          {remitaObject && (
            <form
              action="https://login.remita.net/remita/ecomm/finalize.reg"
              method="POST"
            >
              <TextCenteredInput
                alignText="left"
                value={remitaObject?.response?.merchantCode}
                name="merchantId"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                placeholder="Hash"
                value={remitaObject?.hash}
                name="hash"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                placeholder="RRR"
                value={remitaObject?.response?.rrr}
                name="rrr"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                value={remitaObject?.responseurl}
                type="hidden"
                name="responseurl"
              />
              <CustomButton type="submit">Pay Via Remita</CustomButton>
            </form>
          )}
        </div>
      </ItemsCenterJustifyBtw>
    </Printer>
  );
};

export default ReactPrint;
