import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../Redux/features/hostelSlice";
import CustomTable2 from "../CustomTable2";
import { HostelsCols, hostelHistoryRows } from "./utils";

const HostelHistoryTable = () => {
  const { hostelHistory, gettingHostelHistory } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();
  const rows = hostelHistoryRows(hostelHistory);

  const handlePagination = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getHostelsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: HostelsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingHostelHistory}
      noRecord={hostelHistory?.length === 0 || !hostelHistory}
      page={hostelHistory?.currentPage}
      pageCount={hostelHistory?.totalRecords}
      pageSize={hostelHistory?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
      title="Hostel History"
    />
  );
};

export default HostelHistoryTable;
