import styled from "styled-components";
import { media } from "../../../Screens";

export const LetterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 30px;
`;

export const LetterHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  border: 1px solid #5e9758;
  background: #bad4b5;
  padding: 20px;

  img {
    width: 50px;

    ${media.tablet} {
      width: 100px;
    }
  }
`;

export const SchoolName = styled.p`
  color: var(--clr-black);
  font-weight: var(--fw-bol);
  font-size: 10px;
  text-transform: uppercase;

  ${media.tablet} {
    font-size: 16px;
  }
`;
export const StatusType = styled.p`
  color: var(--clr-grey-400);
  font-weight: var(--fw-bold);
  font-size: 20px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;

  ${media.tablet} {
    font-size: 16px;
  }
`;

export const SchoolPhone = styled.p`
  color: var(--clr-black);
  font-size: 10px;

  ${media.tablet} {
    font-size: 16px;
  }
`;

export const ProfileImage = styled.img`
  width: 200px;
  height: 200px;
`;

export const Property = styled.span`
  font-size: 20px;
  color: var(--clr-black);
  font-weight: var(--fw-bold);
`;
export const Value = styled.span`
  font-size: 18px;
  color: var(--clr-black);
`;
