/* eslint-disable react-hooks/exhaustive-deps */
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { ReceiptBody, ReceiptContainer, ReceiptHeader, SchoolName, SectionTitle, Table, TableHead, TableData, TableRow } from "../Payments/styles";
import ReactPrint from "../../ReactPrint";
import {  DirectionContainer } from "../Dashboard/style";
import { selectClearance } from "../../../Redux/features/clearanceSlice";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";
import { useEffect, useState } from "react";

const ClearanceForm = () => {
  const {  clearanceForm } = useAppSelector(selectClearance);
  const {currentUser} = useAppSelector(selectUser)
  const [subTotal, setSubTotal] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const filterPrice = clearanceForm?.payments?.map((x:any) => {return x?.feeSchedule?.feeDetails[0]?.feeAmount})
    const sum = filterPrice.reduce((partialSum:any, a:any) => partialSum + a, 0);
    setSubTotal(sum)
    setTotal(sum)
  },[clearanceForm])


  return (
    <>
    <LongTransparentCard>
    <LongWhiteCard>
    <ReactPrint pay={false}>
          <ReceiptContainer>
          <ReceiptHeader>
            <img src="/assets/images/PNG/logo.png" alt="logo"/>
            <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
          </ReceiptHeader>
          <PageTitle>Clearance Form</PageTitle>
          <ReceiptBody>
            <ItemsCenterJustifyBtw className="align-start">
              <div >
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Name: </SectionTitle> <span>{currentUser?.fullname}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Matric. Number: </SectionTitle> <span>{clearanceForm?.matricNo}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Department </SectionTitle><span>{clearanceForm?.department?.departmentName}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Level: </SectionTitle><span>{clearanceForm?.level}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Academic Session: </SectionTitle><span>{clearanceForm?.session}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Semester: </SectionTitle><span>{clearanceForm?.semester}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Exam NUmber: </SectionTitle><span>{clearanceForm?.examNumber}</span></div>
              </div>
            </ItemsCenterJustifyBtw>
            <Table>
            <TableRow>
                <TableHead>Description</TableHead>
                <TableHead>Payment(#)</TableHead>
                <TableHead>Total Payment(#)</TableHead>
            </TableRow>
            
            {clearanceForm?.payments?.map((payment:any) => (
            <TableRow>
                <TableHead>{payment?.feeSchedule?.feeDetails[0]?.feeSchedule?.feeScheduleName}: </TableHead>
                <TableData>{payment?.feeSchedule?.feeDetails[0]?.feeAmount}</TableData>
                <TableData>{payment?.feeSchedule?.feeDetails[0]?.feeAmount}</TableData>
            </TableRow>
            ))}
            </Table>    
            <Table> 
            <TableRow>
                <TableHead>SubTotal: </TableHead>
                <TableData>{subTotal}</TableData>
            </TableRow>
            <TableRow>
                <TableHead>VAT: </TableHead>
                <TableData>0.00</TableData>
              </TableRow>
            <TableRow>
                <TableHead>Total: </TableHead>
                <TableData>{total}</TableData>
              </TableRow>
            </Table>
          </ReceiptBody>
          <DirectionContainer>
                {/* <Description><BsInfoCircleFill color='red' size={20}/>Note: you will be charged #500 for the services</Description> */}
            </DirectionContainer>
          </ReceiptContainer>
        </ReactPrint>
      </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default ClearanceForm;
