import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import {
  LetterContainer,
  LetterHeader,
  ProfileImage,
  Property,
  SchoolName,
  SchoolPhone,
  StatusType,
  Value,
} from "./style";
import ReactPrint from "../../ReactPrint";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectAdmission } from "../../../Redux/features/admissionSlice";
import { useEffect } from "react";
import { getApplicantsDetails } from "../../../Apis/Private/Admission";
import Loader from "../../../Loaders/MoonLoader";
import { ItemsCenterJustifyBtw } from "../style";

const AdmissionStatus = () => {
  const { applicantDetails, gettingApplicantsDetails } =
    useAppSelector(selectAdmission);

  useEffect(() => {
    getApplicantsDetails();
  }, []);

  return (
    <LongTransparentCard>
      <LongWhiteCard>
        {gettingApplicantsDetails ? (
          <Loader size={20} color="#f72585" />
        ) : (
          <>
            {applicantDetails ? (
              <ReactPrint isAdmitted={applicantDetails?.isAdmitted}>
                <LetterContainer>
                  <LetterHeader>
                    <img src="/assets/images/PNG/logo.png" alt="logo" />
                    <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                    <SchoolPhone>
                      09134273215 | 07061756730 | 09121963049 | 09121767243
                    </SchoolPhone>
                  </LetterHeader>
                  <StatusType>
                    Admission Status:{" "}
                    {applicantDetails?.isAdmitted
                      ? "Admitted"
                      : "Processing..."}
                  </StatusType>
                  {/* <Divider /> */}
                  <ProfileImage
                    src={
                      "https://erp.gouni.edu.ng/Content/Uploads/Images/defaultpersonimage1.png"
                    }
                    alt="profile Image"
                  />
                  <ItemsCenterJustifyBtw style={{ alignItems: "flex-start" }}>
                    <div>
                      <div>
                        <Property>Name: </Property>
                        <Value>
                          {applicantDetails?.data?.firstName +
                            " " +
                            applicantDetails?.data?.lastName +
                            " " +
                            applicantDetails?.data?.otherName}
                        </Value>
                      </div>
                      <div>
                        <Property>Faculty: </Property>
                        <Value>{applicantDetails?.faculty}</Value>
                      </div>
                      <div>
                        <Property>Department: </Property>
                        <Value>{applicantDetails?.department}</Value>
                      </div>
                      <div>
                        <Property>Country: </Property>
                        <Value>{applicantDetails?.data?.countryName}</Value>
                      </div>
                    </div>
                    <div>
                      <div>
                        <Property>State: </Property>
                        <Value>{applicantDetails?.data?.stateName}</Value>
                      </div>
                      <div>
                        <Property>Local Government: </Property>
                        <Value>
                          {applicantDetails?.data?.localGovernmentName}
                        </Value>
                      </div>
                      <div>
                        <Property>Tribe: </Property>
                        <Value>{applicantDetails?.data?.tribeName}</Value>
                      </div>
                      <div>
                        <Property>Religion: </Property>
                        <Value>{applicantDetails?.data?.religionName}</Value>
                      </div>
                      <div>
                        <Property>Mobile Number: </Property>
                        <Value>{applicantDetails?.data?.mobilePhone}</Value>
                      </div>
                      <div>
                        <Property>Gender: </Property>
                        <Value>{applicantDetails?.data?.genderName}</Value>
                      </div>
                    </div>
                  </ItemsCenterJustifyBtw>
                </LetterContainer>
              </ReactPrint>
            ) : (
              ""
            )}
          </>
        )}
      </LongWhiteCard>
    </LongTransparentCard>
  );
};

export default AdmissionStatus;
