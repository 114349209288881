import { Link } from "react-router-dom";
import { Dropdown, Popover, Whisper, Button } from "rsuite";
import styled from "styled-components";
import { Avatar } from "../../Nav/Header/style";
import { FaUserGraduate } from "react-icons/fa";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:hover {
    background: none;
  }
  svg {
    font-size: 16px;
    color: var(--clr-black);
  }
`;

const PopoverContainer = styled(Popover)`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--clr-white-100);
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(2px);

  .rs-dropdown-item {
    color: var(--clr-black);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: 16px;
  }
`;

const renderSpeaker = (
  { onClose, left, top, className, ...rest }: any,
  ref: any
) => {
  const handleSelect = (eventKey: any) => {
    onClose();
  };
  return (
    <PopoverContainer
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu onSelect={handleSelect}>
        <Dropdown.Item eventKey={1}>
          <span
            onClick={() => {
              localStorage.clear();
              window.location.reload();
              window.location.replace(`/`);
            }}
            style={{ color: `#000` }}
          >
            Logout
          </span>
        </Dropdown.Item>
        <Dropdown.Item eventKey={2}>
          <Link to={`/settings/profile`} style={{ color: `#000` }}>
            Profile
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </PopoverContainer>
  );
};

const UserActionDropdown = (item: any) => {
  const { currentUser } = useAppSelector(selectUser);

  return (
    <Whisper placement="bottomStart" trigger="click" speaker={renderSpeaker}>
      <ButtonContainer>
        <Avatar>
          {currentUser?.profileImageUrl ? (
            <img
              src={currentUser?.profileImageUrl}
              alt="profile-img"
              width={50}
              height={50}
              style={{
                borderRadius: "50%",
                border: "1px solid black",
                padding: "5px",
              }}
            />
          ) : (
            <FaUserGraduate className="profile-picture" />
          )}
        </Avatar>
      </ButtonContainer>
    </Whisper>
  );
};

export default UserActionDropdown;
