import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import {
  setActiveBlockRoomByBlockId,
  setActiveBunkByRoomId,
  setAllocatedHostel,
  setChoosingHostel,
  setChosenHostel,
  setGettingActiveBlockRoomByBlockId,
  setGettingActiveBunkByRoomId,
  setGettingHoostelHistory,
  setGettingHostelActiveBlockByHostelId,
  setHostelActiveBlockByHostelId,
  setHostelCard,
  setHostelHistory,
  setPrintingHostelCard,
  setSavingHostelSelection,
} from "../../Redux/features/hostelSlice";

export const printHostelCard = async (hostelAllocationId: number | null) => {
  store.dispatch(setPrintingHostelCard(true));
  const params = {
    hostelAllocationId,
  };
  await api
    .get("/Student/PrintHostelCard", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelCard(data));
      store.dispatch(setPrintingHostelCard(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setPrintingHostelCard(false));
    });
};

export const saveHostelSelection = async (
  semesterId: number | null,
  BunkId: number | null
) => {
  store.dispatch(setSavingHostelSelection(true));
  const params = {
    BunkId,
    semesterId,
  };
  await api
    .get("/Student/HostelSelection/save", { params })
    .then((res) => {
      // const { data } = res;
      const { data } = res;
      store.dispatch(setSavingHostelSelection(false));
      store.dispatch(setAllocatedHostel(data));
      toast.success("Hostel successfully booked");
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setSavingHostelSelection(false));
    });
};

export const getActiveBunkByRoomId = async (RoomBunkId: number | null,SemesterId:number |null) => {
  store.dispatch(setGettingActiveBunkByRoomId(true));
  const params = {
    RoomBunkId,
    SemesterId
  };
  await api
    .get("/Student/GetActiveBunkByRoomId", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingActiveBunkByRoomId(false));
      store.dispatch(setActiveBunkByRoomId(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveBunkByRoomId(false));
    });
};

export const getActiveBlockRoomByBlockId = async (BlockId: number | null, SemesterId:number |null) => {
  store.dispatch(setGettingActiveBlockRoomByBlockId(true));
  const params = {
    BlockId,
    SemesterId
  };
  await api
    .get("/Student/GetActiveBlockRoomByBlockId", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingActiveBlockRoomByBlockId(false));
      store.dispatch(setActiveBlockRoomByBlockId(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveBlockRoomByBlockId(false));
    });
};

export const getHostelActiveBlockByHostelId = async (
  HostelId: number | null
) => {
  store.dispatch(setGettingHostelActiveBlockByHostelId(true));

  const params = {
    HostelId,
  };
  await api
    .get("/Student/GetHostelActiveBlockByHostelId", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingHostelActiveBlockByHostelId(false));
      store.dispatch(setHostelActiveBlockByHostelId(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostelActiveBlockByHostelId(false));
    });
};

export const getHostelsToChoose = async (SemesterId: number | null) => {
  store.dispatch(setAllocatedHostel(null));
  store.dispatch(setChoosingHostel(true));
  const params = {
    SemesterId,
  };
  await api
    .get("/Student/ChooseHostel", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setChoosingHostel(false));
      if (data?.matricNo) {
        toast.info("A hostel has been allocated to you already");
        store.dispatch(setAllocatedHostel(data));
      } else {
        store.dispatch(setChosenHostel(data));
      }
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setChoosingHostel(false));
    });
};

export const getHostelHistory = async (
  sessionId: number | null,
  semesterId: number | null
) => {
  store.dispatch(setGettingHoostelHistory(true));
  const params = {
    sessionId,
    semesterId,
  };
  await api
    .get("/Student/GetHostelHistory", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingHoostelHistory(false));
      store.dispatch(setHostelHistory(data));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHoostelHistory(false));
    });
};
