import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginUserWithId } from "../../../Apis/Auth/Account";
import Overlay from "../../../Loaders/Overlay";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";

const LoginWithId = () => {
  const { logingUser } = useAppSelector(selectUser);
  const { id } = useParams();

  useEffect(() => {
    loginUserWithId (id);
  }, [id]);

  return <>{logingUser && <Overlay loadingText="Authenticating..."/>}</>;
};

export default LoginWithId;
