import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface HostelState {
  printingHostelCard: boolean;
  hostelCard: any;
  savingHostelSelection: boolean;
  gettingActiveBunkByRoomId: boolean;
  activeBunkByRoomId: any;
  gettingActiveBlockRoomByBlockId: boolean;
  activeBlockRoomByBlockId: any;
  gettingHostelActiveBlockByHostelId: boolean;
  hostelActiveBlockByHostelId: any;
  choosingHostel: boolean;
  chosenHostel: any;
  allocatedHostel: any;
  gettingHostelHistory: boolean;
  hostelHistory: any;
  allocationId: number | null;

  sessionId: number | null;
  semesterId: number | null;
  hostelId: number | null;
  blockId: number | null;
  roomId: number | null;
  bunkId: number | null;
}

const initialState: HostelState = {
  printingHostelCard: false,
  hostelCard: null,
  savingHostelSelection: false,
  gettingActiveBunkByRoomId: false,
  activeBunkByRoomId: null,
  gettingActiveBlockRoomByBlockId: false,
  activeBlockRoomByBlockId: null,
  gettingHostelActiveBlockByHostelId: false,
  hostelActiveBlockByHostelId: null,
  choosingHostel: false,
  chosenHostel: null,
  allocatedHostel: null,
  gettingHostelHistory: false,
  hostelHistory: null,
  allocationId: null,

  sessionId: null,
  semesterId: null,
  hostelId: null,
  blockId: null,
  roomId: null,
  bunkId: null,
};

export const HostelSlice = createSlice({
  name: "Hostel",
  initialState,
  reducers: {
    setPrintingHostelCard: (state, action) => {
      state.printingHostelCard = action.payload;
    },
    setHostelCard: (state, action) => {
      state.hostelCard = action.payload;
    },
    setSavingHostelSelection: (state, action) => {
      state.savingHostelSelection = action.payload;
    },
    setGettingActiveBunkByRoomId: (state, action) => {
      state.gettingActiveBunkByRoomId = action.payload;
    },
    setActiveBunkByRoomId: (state, action) => {
      state.activeBunkByRoomId = action.payload;
    },
    setGettingActiveBlockRoomByBlockId: (state, action) => {
      state.gettingActiveBlockRoomByBlockId = action.payload;
    },
    setActiveBlockRoomByBlockId: (state, action) => {
      state.activeBlockRoomByBlockId = action.payload;
    },
    setGettingHostelActiveBlockByHostelId: (state, action) => {
      state.gettingHostelActiveBlockByHostelId = action.payload;
    },
    setHostelActiveBlockByHostelId: (state, action) => {
      state.hostelActiveBlockByHostelId = action.payload;
    },
    setChoosingHostel: (state, action) => {
      state.choosingHostel = action.payload;
    },
    setChosenHostel: (state, action) => {
      state.chosenHostel = action.payload;
    },
    setAllocatedHostel: (state, action) => {
      state.allocatedHostel = action.payload;
    },
    setGettingHoostelHistory: (state, action) => {
      state.gettingHostelHistory = action.payload;
    },
    setHostelHistory: (state, action) => {
      state.hostelHistory = action.payload;
    },

    resetHostelParams: (state) => {
      state.printingHostelCard = false;
      state.hostelCard = null;
      state.savingHostelSelection = false;
      state.gettingActiveBunkByRoomId = false;
      state.activeBunkByRoomId = null;
      state.gettingActiveBlockRoomByBlockId = false;
      state.activeBlockRoomByBlockId = null;
      state.gettingHostelActiveBlockByHostelId = false;
      state.hostelActiveBlockByHostelId = null;
      state.choosingHostel = false;
      state.chosenHostel = null;
      state.allocatedHostel = null;
      state.gettingHostelHistory = false;
      state.hostelHistory = null;
      state.allocationId = null;

      state.sessionId = null;
      state.semesterId = null;
      state.hostelId = null;
      state.blockId = null;
      state.roomId = null;
      state.bunkId = null;
    },
    getHostelsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setActiveBlockRoomByBlockId,
  setActiveBunkByRoomId,
  setChoosingHostel,
  setChosenHostel,
  setGettingActiveBlockRoomByBlockId,
  setGettingActiveBunkByRoomId,
  setGettingHostelActiveBlockByHostelId,
  setHostelActiveBlockByHostelId,
  setPrintingHostelCard,
  setSavingHostelSelection,
  getHostelsFormDetails,
  setAllocatedHostel,
  setGettingHoostelHistory,
  setHostelHistory,
  setHostelCard,
  resetHostelParams,
} = HostelSlice.actions;
export const selectHostel = (state: RootState) => state.hostel;

export default HostelSlice.reducer;
