import { toast } from "react-toastify";
import api from "../baseUri";
import { setUpdatingProfilePicture } from "../../Redux/features/userSlice";
import { store } from "../../Redux/app/store";

export const changeProfilePicture = async (file: any) => {
  store.dispatch(setUpdatingProfilePicture(true));
  const body = { file };
  await api
    .post("Utill/UploadWithCloudinary", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(async (res) => {
      store.dispatch(setUpdatingProfilePicture(false));
      const { data } = res;
      const params = {
        imageUrl: data.url,
      };
      await api
        .get("Utill/GetProfileImage/set", {
          params,
        })
        .then((res) => {
          if (res?.data) {
            toast.success(
              "Profile Image updated. It will effect on your next login."
            );
            store.dispatch(setUpdatingProfilePicture(false));
          }
        })
        .catch((err: any) => {
          if (err?.response) toast.error(err?.response?.data);
          if (err?.message === "Network Error") toast.error(err?.message);
          store.dispatch(setUpdatingProfilePicture(false));
        });
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setUpdatingProfilePicture(false));
    });
};
