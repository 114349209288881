import { useEffect, useState } from "react";
import { NavContainer, NavItem, NavMenu, SidebarContainer } from "./style";
import { Sidenav } from "rsuite";
import "rsuite/dist/rsuite.css";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectNav } from "../../../Redux/features/navSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { NavItems } from "./Utils";
import { selectUser } from "../../../Redux/features/userSlice";
import { resetHostelParams } from "../../../Redux/features/hostelSlice";

const Sidebar = () => {
  // const [expanded, setExpanded] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const { showSidebar } = useAppSelector(selectNav);
  const { currentUser } = useAppSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    pathname === `/` && setActiveKey("1");
    pathname.includes(`/admission/admission-letter`) && setActiveKey("2-1");
    pathname.includes(`/admission/acknowledgement-slip`) && setActiveKey("2-2");
    pathname === `/clearance` && setActiveKey("3");
    pathname === `/result` && setActiveKey("4");
    pathname === `/course-registration` && setActiveKey("5");
    pathname === `/hostel` && setActiveKey("6");
    pathname === `/hostel/hostel-booking` && setActiveKey("6-1");
    pathname === `/hostel/hostel-history` && setActiveKey("6-2");
    pathname === `/hostel/print-hostel-card` && setActiveKey("6-2");
    pathname === `/hostel/hostel-active-block-room` && setActiveKey("6-3");
    pathname === `/account` && setActiveKey("7");
    pathname === `/change-password` && setActiveKey("7");
    pathname === `/payment/payment-history` && setActiveKey("8-1");
    pathname === `/payment/view-payment-history` && setActiveKey("8-1");
    pathname === `/payment/generate-invoice` && setActiveKey("8-2");
    pathname === `/payment/view-generated-invoice` && setActiveKey("8-2");
  }, [pathname]);

  return (
    <SidebarContainer showSidebar={showSidebar}>
      <Sidenav
        defaultOpenKeys={["2", "6", "8"]}
        style={{
          height: `100%`,
          overflowY: `auto`,
          background: `none`,
          position: "relative",
        }}
      >
        <Sidenav.Body>
          <NavContainer
            activeKey={activeKey}
            onSelect={setActiveKey}
            appearance="tabs"
          >
            {currentUser?.roleId === 10 &&
              NavItems?.map((nav: any) => (
                <>
                  {nav?.hasChildren ? (
                    <NavMenu
                      placement="rightStart"
                      eventKey={nav?.id}
                      title={nav?.parentName}
                      style={{ background: `none` }}
                      // icon={nav?.icon}
                    >
                      {nav?.Children?.map((childNav: any) => (
                        <NavItem
                          eventKey={childNav?.id}
                          icon={childNav?.icon}
                          onClick={() => {
                            navigate(`${childNav?.childLink}`);
                            dispatch(resetHostelParams());
                          }}
                        >
                          {childNav?.childName}
                        </NavItem>
                      ))}
                    </NavMenu>
                  ) : (
                    <NavItem
                      eventKey={nav?.id}
                      icon={nav?.icon}
                      onClick={() => {
                        navigate(`${nav?.parentLink}`);
                        dispatch(resetHostelParams());
                      }}
                    >
                      {nav?.parentName}
                    </NavItem>
                  )}
                </>
              ))}
          </NavContainer>
        </Sidenav.Body>
        {/* <Sidenav.Toggle expanded={expanded} onToggle={(expanded) => setExpanded(expanded)} style={{position: "static", bottom:"30px", width: "100%",}}/> */}
      </Sidenav>
    </SidebarContainer>
  );
};

export default Sidebar;
