import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface CourseRegState {
  registeringCourses: boolean;

  semesterId: number | null;
  sessionId: number | null;
}

const initialState: CourseRegState = {
  registeringCourses: false,

  semesterId: null,
  sessionId: null,
};

export const CourseRegSlice = createSlice({
  name: "CourseReg",
  initialState,
  reducers: {
    setRegisteringCourses: (state, action) => {
      state.registeringCourses = action.payload;
    },
    getCourseRegFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const { setRegisteringCourses, getCourseRegFormDetails } =
  CourseRegSlice.actions;
export const selectCourseReg = (state: RootState) => state.courseReg;

export default CourseRegSlice.reducer;
