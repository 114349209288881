import React from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { useAppSelector } from "../../../Redux/app/hooks";
import HostelHistoryFilter from "./HostelHistoryFilter";
import { getHostelHistory } from "../../../Apis/Private/hostel";
import HostelHistoryTable from "../../../Tables/Hostel/HostelHistory";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import { toast } from "react-toastify";

const HostelHistory = () => {
  const { sessionId, semesterId } = useAppSelector(selectHostel);

  const handleHostelFilter = () => {
    if (sessionId) {
      getHostelHistory(sessionId, semesterId);
    } else {
      toast.info("Please select session to filter");
    }
  };

  return (
    <div>
      <ItemsCenterJustifyBtw>
        <PageTitle>Hostel History</PageTitle>
        <HostelHistoryFilter filterFunction={handleHostelFilter} />
      </ItemsCenterJustifyBtw>
      <HostelHistoryTable />
    </div>
  );
};

export default HostelHistory;
