import React from "react";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { filter } from "../util";
import { PageTitle } from "../style";
import UnderlinedSelector from "../../../Components/Form/Dropdowns/UnderlinedSelector";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import {
  getResultsFormDetails,
  selectResult,
} from "../../../Redux/features/resultSlice";
import { checkResult } from "../../../Apis/Private/Result";
import { toast } from "react-toastify";

const Result = () => {
  const { semesters, sessions } = useAppSelector(selectUtil);
  const { sessionId, checkingResult, semesterId } =
    useAppSelector(selectResult);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getResultsFormDetails({ name, value }));
  };

  const handleCheckResult = (e: any) => {
    e.preventDefault();
    if (sessionId) {
      checkResult(sessionId, semesterId);
    } else {
      toast.error("Please put in the session");
    }
  };

  return (
    <>
      <PageTitle>Check Result</PageTitle>
      <div className="flex-centered-items flex-wrap">
        <UnderlinedSelector
          dropdownItems={sessionFilter}
          title={`Session`}
          handleDropdownSelect={handleSelect}
          name="sessionId"
          value={sessionId}
        />
        <UnderlinedSelector
          dropdownItems={semesterFilter}
          title={`Semester`}
          handleDropdownSelect={handleSelect}
          name="semesterId"
          value={semesterId}
        />
      </div>
      <SmallButton
        buttonText="Check"
        handleClick={handleCheckResult}
        loading={checkingResult}
      />
    </>
  );
};

export default Result;
