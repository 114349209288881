import React, { useEffect } from "react";
import { PageTitle } from "../style";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import { generateInvoice } from "../../../Apis/Private/Payment";
import InvoicePage from "../Payments/InvoicePage";
import { getApplicantsDetails } from "../../../Apis/Private/Admission";
import { selectAdmission } from "../../../Redux/features/admissionSlice";
import Loader from "../../../Loaders/MoonLoader";

const GenerateInvoice = () => {
  const { generatingInvoice, generatedInvoice } = useAppSelector(selectPayment);
  const { applicantDetails, gettingApplicantsDetails } =
    useAppSelector(selectAdmission);

  useEffect(() => {
    getApplicantsDetails();
  }, []);

  useEffect(() => {
    generateInvoice(
      applicantDetails?.feeScheduleId,
      applicantDetails?.sessionId
    );
  }, [applicantDetails]);

  return (
    <>
      <PageTitle>Acceptance Fee Invoice</PageTitle>
      {gettingApplicantsDetails || generatingInvoice ? (
        <Loader size={20} color="#f72585" />
      ) : (
        generatedInvoice && <InvoicePage />
      )}
    </>
  );
};

export default GenerateInvoice;
