import React, { useState } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  PageTitle,
} from "../style";
import InnerNavTabs from "../../../Components/Nav/InnerNavTabs";
import { TabList } from "./utils";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import { ImageSectionContainer } from "./style";
import { changeProfilePicture } from "../../../Apis/Private/settings";
import { toast } from "react-toastify";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";

const Account = () => {
  const { currentUser, updatingProfilePicture } = useAppSelector(selectUser);
  const [file, setFile] = useState();

  const handleFileChange = (e: any) => {
    e.preventDefault();
    const { files } = e.target;
    setFile(files[0]);
  };

  const handleProfilePictureChange = (e: any) => {
    e.preventDefault();
    if (file) {
      changeProfilePicture(file);
    } else {
      toast.error("No file selected");
    }
  };

  return (
    <>
      <PageTitle>Account</PageTitle>
      <InnerNavTabs tabList={TabList} />
      <FormSectionContainer>
        <FormCardContainer>
          <ImageSectionContainer>
            <img src={currentUser?.profileImageUrl} alt="profile" />
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <input
                type="file"
                name=""
                id=""
                accept=".jpg, .jpeg, .png, .webp, .svg, .jfif"
                onChange={handleFileChange}
              />
              <SmallButton
                buttonText="Save"
                handleClick={handleProfilePictureChange}
                loading={updatingProfilePicture}
              />
            </div>
          </ImageSectionContainer>
          <Form>
            <TextCenteredInput
              placeholder="Fullname"
              alignText="left"
              value={currentUser?.fullname}
            />
            <TextCenteredInput
              placeholder="Username"
              alignText="left"
              value={currentUser?.username}
            />
          </Form>
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default Account;
