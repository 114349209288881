import styled from "styled-components";

export const ImageSectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  img {
    width: 250px;
    height: 250px;
  }

  input:file {
    border: none;
    width: 100%;
    border-radius: 10px;
  }
`;
