import React from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectUser } from "../../../Redux/features/userSlice";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import {
  Description,
  DirectText,
  DirectionContainer,
  WelcomeMessage,
} from "./style";
import { Divider } from "rsuite";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { useNavigate } from "react-router-dom";
import { BsInfoCircleFill } from "react-icons/bs";

const Dashboard = () => {
  const { currentUser } = useAppSelector(selectUser);
  const navigate = useNavigate();

  return (
    <>
      <WelcomeMessage>Welcome {currentUser?.fullname}</WelcomeMessage>
      <LongTransparentCard>
        <LongWhiteCard>
          <DirectionContainer>
            <DirectText>Account Settings</DirectText>
            <Description>
              Your password was automatically generated on your registration
              please go to account settings to change your password
            </Description>
            <SmallButton
              buttonText="Change Password"
              handleClick={() => {
                navigate("/account");
              }}
              textTransform="capitalize"
            />
          </DirectionContainer>
          <Divider />
          <DirectionContainer>
            <DirectText>Course Registration</DirectText>
            <Description>
              Course registration is one of the requirements you must complete
              before your exams can be computed. Please click on the button
              below or navigate through the sidebar to complete your course
              registration
            </Description>
            <SmallButton
              buttonText="Complete Course Reg"
              handleClick={() => {
                navigate("/course-registration");
              }}
              textTransform="capitalize"
            />
          </DirectionContainer>
          {/* <Divider />
            <DirectionContainer>
                <Description><BsInfoCircleFill color='gold' size={20}/> Hostels are open now. you can book your hostel</Description>
            </DirectionContainer> */}
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default Dashboard;
