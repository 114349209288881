import React, { FC } from "react";
import { QRCodeCanvas } from "qrcode.react";

document.getElementById("mountNode");

interface QrCodeProps {
  value: string;
  customStyles?: any;
}

const QRCode: FC<QrCodeProps> = ({ value, customStyles }) => {
  return <QRCodeCanvas value={value} style={customStyles} />;
};

export default QRCode;
