import PaymentMoreDropdown from "../../Components/Form/Dropdowns/PaymentMoreDropdown";
import HostelHistoryMoreDropdown from "../../Components/Form/Dropdowns/HostelHistoryMoreDropdown";

// brands table data
export const HostelsCols = [
  { label: "HostelName", field: "hostelName" },
  { label: "BlockName", field: "blockName" },
  { label: "Session", field: "sessionName" },
  { label: "Semester", field: "semester" },
  { label: "Action", field: "action2" },
];

export const hostelHistoryRows = (data: any, type?: string) => {
  const res = data?.map((history: any) => {
    return {
      hostelName: <span>{history?.hostelName}</span>,
      blockName: <span>{history?.blockName}</span>,
      sessionName: <span>{history?.sessionName}</span>,

      semester: (
        <span>
          {history?.semesterId === 1
            ? "First Semester"
            : history?.semesterId === 2
            ? "Second Semester"
            : "N/A"}
        </span>
      ),

      action: <PaymentMoreDropdown paymentId={history?.history} />,
      action2: (
        <HostelHistoryMoreDropdown
          bunkId={history?.bunkId}
          semesterId={history?.semesterId}
          allocationId={history?.allocationId}
        />
      ),
    };
  });

  return res;
};
