import { PiStudentBold } from "react-icons/pi";

import {
  MdClearAll,
  // MdOutlineBedroomParent,
  MdOutlineHouseSiding,
} from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GrSelect } from "react-icons/gr";
import { RiAccountPinCircleLine, RiHistoryFill } from "react-icons/ri";
import { FaCashRegister, FaFileInvoiceDollar } from "react-icons/fa";

export const NavItems = [
  {
    id: "1",
    parentName: "Dashboard",
    parentLink: "/",
    hasChildren: false,
  },
  // {
  //   id: "2",
  //   parentName: "Admission",
  //   parentLink: "/admission",
  //   hasChildren: true,
  //   icon: <PiStudentBold />,
  //   Children: [
  //     {
  //       id: "2-1",
  //       childName: "Print Admission Letter",
  //       childLink: "/admission/admission-letter",
  //       icon: <SlEnvolopeLetter />,
  //     },
  //     {
  //       id: "2-2",
  //       childName: "Print Acknowledgement Slip",
  //       childLink: "/admission/acknowledgement-slip",
  //       icon: <GiPapers />
  //     },
  //   ],
  // },
  {
    id: "8",
    parentName: "Payments",
    parentLink: "/payment",
    hasChildren: true,
    icon: <PiStudentBold />,
    Children: [
      {
        id: "8-1",
        childName: "Payment history",
        childLink: "/payment/payment-history",
        icon: <RiHistoryFill />,
      },
      {
        id: "8-2",
        childName: "Generate invoice",
        childLink: "/payment/generate-invoice",
        icon: <FaFileInvoiceDollar />,
      },
    ],
  },
  {
    id: "5",
    parentName: "Course Registration",
    parentLink: "/course-registration",
    hasChildren: false,
    icon: <FaCashRegister />,
  },
  {
    id: "4",
    parentName: "Result",
    parentLink: "/result",
    hasChildren: false,
    icon: <HiOutlineDocumentReport />,
  },
  {
    id: "3",
    parentName: "Clearance",
    parentLink: "/clearance",
    hasChildren: false,
    icon: <MdClearAll />,
  },

  {
    id: "6",
    parentName: "Hostel",
    parentLink: "/hostel",
    hasChildren: true,
    icon: <MdOutlineHouseSiding />,
    Children: [
      {
        id: "6-1",
        childName: "Hostel Booking",
        childLink: "/hostel/hostel-booking",
        icon: <GrSelect />,
      },
      {
        id: "6-2",
        childName: "Hostel History",
        childLink: "/hostel/hostel-history",
        icon: <MdOutlineHouseSiding />,
      },
    ],
  },

  {
    id: "7",
    parentName: "Account",
    parentLink: "/account",
    hasChildren: false,
    icon: <RiAccountPinCircleLine />,
  },
];
