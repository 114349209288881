import React, { useEffect } from "react";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import ReactPrint from "../../ReactPrint";
import {
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
} from "../Payments/styles";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { printHostelCard } from "../../../Apis/Private/hostel";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectHostel } from "../../../Redux/features/hostelSlice";
import Loader from "../../../Loaders/MoonLoader";
import { PiCaretLeft } from "react-icons/pi";
import { NavigationLink } from "../../../Tables/styles";
import { Description } from "../Dashboard/style";
import { BsInfoCircleFill } from "react-icons/bs";
import QRCode from "../../../Components/Reusables/QRCode";

const HostelCard = () => {
  const { allocationId, hostelCard, printingHostelCard } =
    useAppSelector(selectHostel);

  useEffect(() => {
    printHostelCard(allocationId);
  }, [allocationId]);

  console.log(process.env.REACT_APP_SERVER_URL);

  return (
    <>
      <PageTitle>Print Hostel Card</PageTitle>
      <NavigationLink
        to={`/hostel/hostel-history`}
        className="flex-centered-items"
      >
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      {printingHostelCard ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader size={20} color="#f72585" />
        </div>
      ) : (
        <>
          {hostelCard && (
            <LongTransparentCard>
              <LongWhiteCard>
                <ReactPrint pay={false}>
                  <ReceiptContainer>
                    <ReceiptHeader>
                      <img src="/assets/images/PNG/logo.png" alt="logo" />
                      <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                      <SchoolPhone>Allocated Hostel Details</SchoolPhone>
                    </ReceiptHeader>
                    <ReceiptBody>
                      <ItemsCenterJustifyBtw>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Name:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.name}
                          </span>
                        </div>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Matric. NO.:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.matricNo}
                          </span>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <ItemsCenterJustifyBtw>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Department:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.department}
                          </span>
                        </div>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Level:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.level}
                          </span>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <ItemsCenterJustifyBtw>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Session:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.session}
                          </span>
                        </div>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Semester:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.semester}
                          </span>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <ItemsCenterJustifyBtw>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Hostel:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.hostel}
                          </span>
                        </div>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Block:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.hostelBlock}
                          </span>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <ItemsCenterJustifyBtw>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Room:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.roomNo}
                          </span>
                        </div>
                        <div
                          className="flex-centered-items"
                          style={{ fontSize: `16px` }}
                        >
                          <span style={{ color: `var(--clr-black)` }}>
                            Bunk:
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {hostelCard?.bunk}
                          </span>
                        </div>
                      </ItemsCenterJustifyBtw>
                    </ReceiptBody>
                    <Description style={{ alignItems: "center" }}>
                      <strong>
                        <BsInfoCircleFill
                          color={
                            hostelCard?.status === "Active" ? "green" : "red"
                          }
                          size={20}
                        />
                        Hostel Allocation is {hostelCard?.status}
                      </strong>
                    </Description>
                    <QRCode
                      value={
                        process.env.REACT_APP_SERVER_URL ===
                        "https://api.dev.report.gouni.demidas.co/api"
                          ? `https://report.gouni.demidas.co/verify-hostel-allocation/${hostelCard?.studentId}/${hostelCard?.semesterId}`
                          : `https://admin.erp.gouni.edu.ng/verify-hostel-allocation/${hostelCard?.studentId}/${hostelCard?.semesterId}`
                      }
                      customStyles={{ width: "200px", height: "200px" }}
                    />
                  </ReceiptContainer>
                </ReactPrint>
              </LongWhiteCard>
            </LongTransparentCard>
          )}
        </>
      )}
    </>
  );
};

export default HostelCard;
