import { useEffect, useState } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { PiArrowRightThin, PiCaretLeft } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";
import { getPaymentReceipt } from "../../../Apis/Private/Payment";
import {
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
  SectionTitle,
  Table,
  TableHead,
  TableData,
  TableRow,
} from "./styles";
import ReactPrint from "../../ReactPrint";
import { Description, DirectionContainer } from "../Dashboard/style";
import Loader from "../../../Loaders/MoonLoader";
import { MdOutlineError } from "react-icons/md";
import { NavigationLink } from "../../../Tables/styles";
import { BsInfoCircleFill } from "react-icons/bs";

const PaymentReceipt = () => {
  const [fetchError, seFetchError] = useState();
  const { id } = useParams();
  const { paymentReceipt, gettingPaymentReceipt } =
    useAppSelector(selectPayment);

  // const {}
  useEffect(() => {
    getPaymentReceipt(Number(id), seFetchError);
  }, [id]);

  return (
    <>
      <PageTitle>Payment Receipt</PageTitle>
      <NavigationLink
        to={`/payment/payment-history`}
        className="flex-centered-items"
      >
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
      <LongTransparentCard>
        <LongWhiteCard>
          {gettingPaymentReceipt ? (
            <Loader size={20} color="#f72585" />
          ) : (
            <>
              {paymentReceipt ? (
                <ReactPrint>
                  <ReceiptContainer>
                    <ReceiptHeader>
                      <img src="/assets/images/PNG/logo.png" alt="logo" />
                      <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                      <SchoolPhone>
                        {paymentReceipt?.payment?.feeSchedule?.feeScheduleName}{" "}
                        receipt
                      </SchoolPhone>
                    </ReceiptHeader>
                    <img
                      src={paymentReceipt?.payment?.person?.passportUrl}
                      alt="profile"
                      width={200}
                      height={200}
                      style={{ borderRadius: "10px" }}
                    />
                    <ReceiptBody>
                      <ItemsCenterJustifyBtw className="align-start">
                        <div style={{ width: "100%" }}>
                          <SectionTitle>From</SectionTitle>
                          <p>
                            {paymentReceipt?.payment?.person?.firstName +
                              paymentReceipt?.payment?.person?.lastName +
                              paymentReceipt?.payment?.person?.otherName}
                          </p>
                          <p>{paymentReceipt?.payment?.person?.emailAddress}</p>
                          <p>
                            {paymentReceipt?.payment?.person?.contactAddress}
                          </p>
                        </div>
                        <PiArrowRightThin size={30} width={100} />
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <SectionTitle>To</SectionTitle>
                            <span>Godfrey Okeye Univeristy</span>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payment type: </SectionTitle>
                              <span>
                                {
                                  paymentReceipt?.payment?.feeSchedule
                                    ?.feeScheduleName
                                }
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>
                                Payment ID/Invoice Number:{" "}
                              </SectionTitle>
                              <span>
                                {paymentReceipt?.payment?.invoiceNumber}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payee ID: </SectionTitle>
                              <span>
                                {
                                  paymentReceipt?.payment?.person
                                    ?.prospectiveStudent?.applicationNumber
                                }
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Invoice Date: </SectionTitle>
                              <span>
                                {moment(
                                  paymentReceipt?.payment?.transactionDate
                                ).format("DD MMM YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <DirectionContainer>
                        <Description>
                          <BsInfoCircleFill color="red" size={20} />
                          {paymentReceipt?.message}
                        </Description>
                      </DirectionContainer>
                      <Table>
                        <TableRow>
                          <TableHead>Item</TableHead>
                          <TableHead>Quantity</TableHead>
                          <TableHead>Unit Price(#)</TableHead>
                          <TableHead>Total Price(#)</TableHead>
                        </TableRow>
                        <TableRow>
                          {paymentReceipt?.payment?.feeSchedule?.feeDetails?.map(
                            (feeDetail: any) => (
                              <>
                                <TableData>{feeDetail?.feeName}</TableData>
                                <TableData>1</TableData>
                                <TableData>{feeDetail?.feeAmount}</TableData>
                                <TableData>{feeDetail?.feeAmount}</TableData>
                              </>
                            )
                          )}
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableHead>SubTotal: </TableHead>
                          <TableData>
                            {paymentReceipt?.payment?.feeSchedule?.feeDetails?.reduce(
                              (accumulator: any, currentValue: any) => {
                                return accumulator + currentValue.feeAmount;
                              },
                              0
                            )}
                          </TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>VAT: </TableHead>
                          <TableData>0.00</TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>Total: </TableHead>
                          <TableData>
                            {paymentReceipt?.payment?.feeSchedule?.feeDetails?.reduce(
                              (accumulator: any, currentValue: any) => {
                                return accumulator + currentValue.feeAmount;
                              },
                              0
                            )}
                          </TableData>
                        </TableRow>
                      </Table>
                    </ReceiptBody>
                  </ReceiptContainer>
                </ReactPrint>
              ) : (
                <Description>
                  <MdOutlineError color="red" size={20} />
                  {fetchError ||
                    `Payment receipt not
                  available`}
                </Description>
              )}
            </>
          )}
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default PaymentReceipt;
