import { Link } from "react-router-dom";
import { Dropdown, Popover, Whisper, Button } from "rsuite";
import styled from "styled-components";
import { Avatar } from "../../Nav/Header/style";
import { MdMoreVert } from "react-icons/md";
import { getHostelsFormDetails } from "../../../Redux/features/hostelSlice";
import { store } from "../../../Redux/app/store";

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  &:hover {
    background: none;
  }
  svg {
    font-size: 16px;
    color: var(--clr-black);
  }
`;

const PopoverContainer = styled(Popover)`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--clr-white-100);
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(2px);

  .rs-dropdown-item {
    color: var(--clr-black);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: 16px;
  }
`;

const renderSpeaker = (
  { onClose, left, top, className, ...rest }: any,
  ref: any,
  bunkId?: number,
  semesterId?: number,
  allocationId?: number
) => {
  const handleSelect = (eventKey: any) => {
    store.dispatch(getHostelsFormDetails({ name: "bunkId", value: bunkId }));
    store.dispatch(
      getHostelsFormDetails({ name: "semesterId", value: semesterId })
    );
    store.dispatch(
      getHostelsFormDetails({ name: "allocationId", value: allocationId })
    );
  };
  return (
    <PopoverContainer
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu onSelect={handleSelect}>
        <Dropdown.Item eventKey={1}>
          <Link to={`/hostel/print-hostel-card`} style={{ color: `#000` }}>
            print Hostel Card
          </Link>
        </Dropdown.Item>
        {/* <Dropdown.Item eventKey={2}>
          <span style={{ color: `#000` }}>Download Receipt</span>
        </Dropdown.Item> */}
      </Dropdown.Menu>
    </PopoverContainer>
  );
};

interface DropdownProp {
  bunkId?: number;
  semesterId?: number;
  allocationId?: number;
}
const HostelHistoryMoreDropdown = ({
  bunkId,
  semesterId,
  allocationId,
}: DropdownProp) => {
  return (
    <Whisper
      placement="bottomStart"
      trigger="click"
      speaker={renderSpeaker(``, ``, bunkId, semesterId, allocationId)}
    >
      <ButtonContainer>
        <Avatar>
          <MdMoreVert />
        </Avatar>
      </ButtonContainer>
    </Whisper>
  );
};

export default HostelHistoryMoreDropdown;
