import React from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getHostelsFormDetails,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import { filter } from "../util";
import { useEffect } from "react";
import {
  getActiveBlockRoomByBlockId,
  getActiveBunkByRoomId,
  getHostelActiveBlockByHostelId,
  getHostelsToChoose,
  saveHostelSelection,
} from "../../../Apis/Private/hostel";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { toast } from "react-toastify";
import UnderlinedSelector from "./../../../Components/Form/Dropdowns/UnderlinedSelector";
import SmallButton from "../../../Components/Form/Buttons/SmallButton";
import { PageTitle } from "../style";
import { ItemsCenterJustifyBtw } from "./../style";
import ReactPrint from "../../ReactPrint";
import {
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
} from "../Payments/styles";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import { Description, DirectionContainer } from "../Dashboard/style";
import { BsInfoCircleFill } from "react-icons/bs";
import QRCode from "../../../Components/Reusables/QRCode";

const Hostel = () => {
  const {
    chosenHostel,
    allocatedHostel,
    hostelActiveBlockByHostelId,
    savingHostelSelection,
    activeBlockRoomByBlockId,
    activeBunkByRoomId,
    semesterId,
    hostelId,
    blockId,
    roomId,
    bunkId,
  } = useAppSelector(selectHostel);
  const { semesters } = useAppSelector(selectUtil);
  const dispatch = useAppDispatch();

  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const hostelsFilter = filter(chosenHostel, "hostelName", "hostelId");
  const hostelsBlockFilter = filter(
    hostelActiveBlockByHostelId,
    "blockName",
    "blockId"
  );
  const hostelsBlockRoomFilter = filter(
    activeBlockRoomByBlockId,
    "text",
    "value"
  );
  const hostelsBlockRoomBunkFilter = filter(
    activeBunkByRoomId,
    "text",
    "value"
  );

  useEffect(() => {
    semesterId && getHostelsToChoose(semesterId);
  }, [semesterId]);

  useEffect(() => {
    hostelId && getHostelActiveBlockByHostelId(hostelId);
  }, [hostelId]);

  useEffect(() => {
    blockId && getActiveBlockRoomByBlockId(blockId,semesterId );
  }, [blockId]);

  useEffect(() => {
    roomId && getActiveBunkByRoomId(roomId, semesterId);
  }, [roomId]);

  const handleHostelSave = (e: any) => {
    e.preventDefault();
    if (semesterId && roomId) {
      saveHostelSelection(semesterId, bunkId);
    } else {
      toast.error(
        "Semester or bunk value is missing. Please fill the fields"
      );
    }
  };

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };

  return (
    <>
      <PageTitle>Hostel Booking</PageTitle>
      <ItemsCenterJustifyBtw>
        <UnderlinedSelector
          dropdownItems={semesterFilter}
          title={`Semester`}
          handleDropdownSelect={handleSelect}
          name="semesterId"
          value={semesterId}
        />
        <UnderlinedSelector
          dropdownItems={hostelsFilter}
          title={`Hostels`}
          handleDropdownSelect={handleSelect}
          name="hostelId"
          value={hostelId}
        />
        <UnderlinedSelector
          dropdownItems={hostelsBlockFilter}
          title={`Block`}
          handleDropdownSelect={handleSelect}
          name="blockId"
          value={blockId}
        />
        <UnderlinedSelector
          dropdownItems={hostelsBlockRoomFilter}
          title={`Room`}
          handleDropdownSelect={handleSelect}
          name="roomId"
          value={roomId}
        />
        <UnderlinedSelector
          dropdownItems={hostelsBlockRoomBunkFilter}
          title={`Bunk`}
          handleDropdownSelect={handleSelect}
          name="bunkId"
          value={bunkId}
        />
      </ItemsCenterJustifyBtw>
      <SmallButton
        buttonText="Save"
        //  leftIcon={<IoMdAdd />}
        loading={savingHostelSelection}
        handleClick={handleHostelSave}
        disabled={allocatedHostel?.matricNo}
      />
      {/* <DirectionContainer style={{ fontSize: "18px" }}>
        <Description style={{ alignItems: "center" }}>
          <strong>
            <BsInfoCircleFill color="gold" size={20} /> IMPORT NOTICE FOR ALL
            STUDENTS
          </strong>
        </Description>

        <p>Exciting News</p>
        <p>
          Get Ready to secure your hostel accomodation for First Semester of the
          2024/2025 Session
        </p>
        <p>
          Due to technical issues, the booking portal will open on July 25,
          2024. Take advantage of this opportunity!.
        </p>
        <p>
          Ensure prompt payment of your fees, and mark your calenders to log in
          to your ERP account{" "}
          <a href="https://student.erp.gouni.edu.ng">
            https://student.erp.gouni.edu.ng
          </a>
          at 08:00 AM on July 25,2024 to reserve your preferred hostel
          accommodation
        </p>
        <p>
          We sincerely apologize for any inconvenience caused by this necessary
          change
        </p>
        <p>Thank you for your understanding.</p>
        <p>
          <strong>
            Step by Step Guide on how to book Hostel Accommodation Online
          </strong>
        </p>
        <ol>
          <li>
            Visit{" "}
            <a href="https://student.erp.gouni.edu.ng">
              https://student.erp.gouni.edu.ng
            </a>
          </li>
          <li>Click on Hostel</li>
          <li>
            Select Session and Semester. The available hostel and rooms will be
            displayed
          </li>
          <li>Select a room and click on save.</li>
          <li>
            Print the hostel card and present it to the hostel moderator upon
            resumption.
          </li>
        </ol>
        <p>
          If you have any challanges, screenshot your screen with the error and
          send it via WhatsApp to +234 8068820118 (WhatsApp only)
        </p>
      </DirectionContainer> */}
      {allocatedHostel && (
        <LongTransparentCard>
          <LongWhiteCard>
            <ReactPrint pay={false}>
              <ReceiptContainer>
                <ReceiptHeader>
                  <img src="/assets/images/PNG/logo.png" alt="logo" />
                  <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                  <SchoolPhone>Allocated Hostel Details</SchoolPhone>
                </ReceiptHeader>
                <ReceiptBody>
                  <ItemsCenterJustifyBtw>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Name:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.name}
                      </span>
                    </div>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>
                        Matric. NO.:
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.matricNo}
                      </span>
                    </div>
                  </ItemsCenterJustifyBtw>
                  <ItemsCenterJustifyBtw>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>
                        Department:
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.department}
                      </span>
                    </div>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Level:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.level}
                      </span>
                    </div>
                  </ItemsCenterJustifyBtw>
                  <ItemsCenterJustifyBtw>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>
                        Session:
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.session}
                      </span>
                    </div>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>
                        Semester:
                      </span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.semester}
                      </span>
                    </div>
                  </ItemsCenterJustifyBtw>
                  <ItemsCenterJustifyBtw>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Hostel:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.hostel}
                      </span>
                    </div>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Block:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.hostelBlock}
                      </span>
                    </div>
                  </ItemsCenterJustifyBtw>
                  <ItemsCenterJustifyBtw>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Room:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.roomNo}
                      </span>
                    </div>
                    <div
                      className="flex-centered-items"
                      style={{ fontSize: `16px` }}
                    >
                      <span style={{ color: `var(--clr-black)` }}>Bunk:</span>
                      <span style={{ fontSize: "14px" }}>
                        {allocatedHostel?.bunk}
                      </span>
                    </div>
                  </ItemsCenterJustifyBtw>
                </ReceiptBody>
                <Description style={{ alignItems: "center" }}>
                      <strong>
                        <BsInfoCircleFill
                          color={
                            allocatedHostel?.status === "Active" ? "green" : "red"
                          }
                          size={20}
                        />
                        Hostel Allocation is {allocatedHostel?.status}
                      </strong>
                    </Description>
                    <QRCode
                      value={
                        process.env.REACT_APP_SERVER_URL ===
                        "https://api.dev.report.gouni.demidas.co/api"
                          ? `https://report.gouni.demidas.co/verify-hostel-allocation/${allocatedHostel?.studentId}/${allocatedHostel?.semesterId}`
                          : `https://admin.erp.gouni.edu.ng/verify-hostel-allocation/${allocatedHostel?.studentId}/${allocatedHostel?.semesterId}`
                      }
                      customStyles={{ width: "200px", height: "200px" }}
                    />
              </ReceiptContainer>
            </ReactPrint>
          </LongWhiteCard>
        </LongTransparentCard>
      )}
    </>
  );
};

export default Hostel;
