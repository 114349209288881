import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { filter } from "../util";
import CustomRegularDropdown from "../../../Components/Form/Dropdowns/CustomRegularDropdown";
import {
  getHostelsFormDetails,
  resetHostelParams,
  selectHostel,
} from "../../../Redux/features/hostelSlice";
import FilterButtons from "../../../Components/Filteration/FilterButtons";

interface FilterProps {
  filterFunction: any;
}

const HostelHistoryFilter: FC<FilterProps> = ({ filterFunction }) => {
  const { sessions } = useAppSelector(selectUtil);
  const { sessionId } = useAppSelector(selectHostel);
  const dispatch = useAppDispatch();

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  // const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };

  return (
    <div className="flex-centered-items flex-wrap">
      <CustomRegularDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="sessionId"
        value={sessionId}
      />
      {/* <CustomRegularDropdown
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="semesterId"
        value={semesterId}
      /> */}
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => dispatch(resetHostelParams())}
      />
    </div>
  );
};

export default HostelHistoryFilter;
